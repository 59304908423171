import React, { useState } from 'react';
import moment from 'moment'; // Ensure moment is imported and installed
import FacilitiesInlineListingCard from './FacilitiesInlineListingCard';
import { DatePicker, TimePicker } from 'antd';
import css from './FacilitiesComponent.css';
import HeaderSearchIconImg from '../../assets/HomePage/HeaderSearchIcon.svg';

const { RangePicker } = DatePicker;

const FacilitiesComponent = (props) => {
  const { className, listings, allClassListingReviews, showAll } = props;

  const [dateTimeRange, setDateTimeRange] = useState({
    start: {
      date: null,
      time: null,
    },
    end: {
      date: null,
      time: null,
    },
  });

  const handleDateRangeChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      console.log("Selected Dates:", start, end); // Debug: Check selected dates
      setDateTimeRange({
        start: { date: start, time: dateTimeRange.start.time },
        end: { date: end, time: dateTimeRange.end.time },
      });
    }
  };

  const handleTimeChange = (time, isStart) => {
    const newTime = time ? time.format('HH:mm') : null;
    setDateTimeRange((prev) => ({
      ...prev,
      [isStart ? 'start' : 'end']: {
        ...prev[isStart ? 'start' : 'end'],
        time: newTime,
      },
    }));
  };

  console.log("llllllolllll",listings)

  const handleSearch = () => {
    const { start, end } = dateTimeRange;
    const searchPayload = {
      startDate: start.date ? start.date.format('YYYY-MM-DD') : null,
      startTime: start.time,
      endDate: end.date ? end.date.format('YYYY-MM-DD') : null,
      endTime: end.time,
    };
    console.log('Search Payload:', searchPayload);
  };
  return (
    <div>
      <div className={css.outerDateSelection}>
        <RangePicker
          onChange={handleDateRangeChange}
          format="YYYY-MM-DD"
          width='100%'
          className={css.outerDate}
        />
        <div style={{ marginTop: 0 }} className={css.outerDateSelectionWrapper}>
          <TimePicker
            placeholder="Start Time"
            onChange={(time) => handleTimeChange(time, true)}
            format="HH:mm"
          />
          <TimePicker
            placeholder="End Time"
            onChange={(time) => handleTimeChange(time, false)}
            format="HH:mm"
          />
        </div>
        <div className={css.searchIcon} onClick={handleSearch}>
          <img src={HeaderSearchIconImg} alt=" " />
          Search
        </div>
      </div>
      {listings.map((l) => (
        <FacilitiesInlineListingCard
          className={className}
          key={l.id.uuid}
          listing={l}
          review={allClassListingReviews ? allClassListingReviews[l.id.uuid] : []}
          selectedDate={dateTimeRange}
          showAll={showAll}
        />
      ))}
    </div>
  );
};

export default FacilitiesComponent;
