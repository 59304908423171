import React, { Component } from 'react';
import PropTypes, { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { calculateQuantityFromHours, timestampToDate } from '../../util/dates';
import { propTypes, defaultListingCurrency } from '../../util/types';
import config from '../../config';
import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  CustomFieldTextInput,
  SimplePackages,
  FieldSelectOutline,
  FieldCurrencyInput,
} from '../../components';
import { formatMoney } from '../../util/currency';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import { isSeatsValueValid, composeValidators } from 'util/validators';

import css from './BookingTimeForm.css';
import moment from 'moment';
import { LISTING_TYPES } from 'util/constants';
import ClassBookingFormFields from 'forms/BookingTimeForm/ClassBookingFormFields';
import { Money } from 'forms/EditListingDescriptionForm/EditListingDescriptionForm.data';

const priceData = (price, intl) => {
  if (
    price &&
    (price.currency === config.currency || defaultListingCurrency.includes(price.currency))
  ) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);
    const { currentUser, listing } = this.props;
    const credits =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile.privateData.sessions &&
      currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
        ? currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
        : 0;
    const hasCredits = credits && credits > 0;

    this.directPaymentTabRef = React.createRef();
    this.periodRef = React.createRef();
    this.state = {
      invalidDates: false,
      selectedNumberOfHours: null,
      activeTab: hasCredits ? 'packages' : 'direct-payment',
      isCustomModalOpen: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onInvalidDateTime = this.onInvalidDateTime.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onPeriodChange = this.onPeriodChange.bind(this);
    this.onChangePrice = this.onChangePrice.bind(this);
  }

  onChangePrice(e) {
    if (e && e.amount && e.amount > 0) {
      this.props.onAddingCustomPrice(e.amount);
    } else {
      this.props.onRemovingCustomPrice();
    }
  }

  handleFormSubmit(e) {
    const { currentUser, listing } = this.props;
    const credits =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile.privateData.sessions &&
      currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
        ? currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
        : 0;
    const hasCredits = credits && credits > 0;

    const sartDateTime = timestampToDate(e.bookingStartTime);
    const endDateTime = timestampToDate(e.bookingEndTime);
    if (this.state.activeTab === 'packages' && hasCredits) {
      const numberOfhours =
        endDateTime && sartDateTime
          ? moment.duration(moment(endDateTime).diff(moment(sartDateTime))).asHours()
          : null;
      const periodValue = e.period || 1;
      if (numberOfhours * Number(periodValue) > credits) {
        this.onInvalidDateTime(numberOfhours);
      } else {
        this.props.onSubmit(e);
      }
    } else {
      this.props.onSubmit(e);
    }
  }

  onInvalidDateTime(numberOfHours) {
    const { currentUser, listing } = this.props;
    const credits =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile.privateData.sessions &&
      currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
        ? currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
        : 0;

    const periodValue =
      this.periodRef && this.periodRef.current ? this.periodRef.current.value : null;

    const invalid = periodValue ? numberOfHours * Number(periodValue) > credits : true;

    if (invalid) {
      this.setState({
        invalidDates: true,
        selectedNumberOfHours: numberOfHours,
      });
    } else {
      this.setState({
        invalidDates: false,
        selectedNumberOfHours: numberOfHours,
      });
    }
  }

  onPeriodChange(value) {
    const { selectedNumberOfHours } = this.state;
    this.onInvalidDateTime(selectedNumberOfHours);
  }

  onSelect(index) {
    if (index === 1) {
      this.setState({
        activeTab: 'packages',
      });
    } else {
      this.setState({
        invalidDates: false,
        activeTab: 'direct-payment',
      });
    }
  }

  classTypeBookingValidation = values => {
    const errors = {};
    if (!values.bookingStartDate) {
      errors.bookingStartDate = 'Required';
    }
    return errors;
  };

  getFormInitData = () => {
    const { monthlyTimeSlots, isProduct } = this.props;
    if (!isProduct) {
      return {};
    }

    const initData = monthlyTimeSlots ? Object.entries(monthlyTimeSlots) : [];
    const initDate = initData.length ? initData[0][1] : {};
    const startTime = initDate?.timeSlots ? initDate.timeSlots[0].attributes.start : null;
    const endTime = initDate.timeSlots ? initDate.timeSlots[0].attributes.end : null;

    return {
      bookingStartDate: { date: startTime },
      bookingEndDate: { date: endTime },
      bookingStartTime: moment(startTime).valueOf(),
      bookingEndTime: moment(startTime)
        .add(1, 'hours')
        .valueOf(),
      timeSlotsOnSelectedDate: initDate.timeSlots,
      seats: '1',
      period: '1',
    };
  };

  render() {
    const {
      rootClassName,
      className,
      handlePackagesModalOpen,
      showTopup,
      price: unitPrice,
      isProduct,
      onManageDisableScrolling,
      ComponentOverlap,
      onSkip,
      bookingDataFacility,
      isTimeSlotesLoading,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const listingType = rest.listing.attributes.publicData.listing_type;
    const hasRequest = rest.listing?.attributes?.publicData?.payment_method?.includes('requestOnly')
      ? true
      : false;

    const hasRequestOnly =
      hasRequest && rest.listing?.attributes?.publicData?.payment_method?.length === 1
        ? true
        : false;

    if (!unitPrice && listingType === LISTING_TYPES.ENQUIRY) {
      return '';
    }
    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (!defaultListingCurrency.includes(unitPrice.currency)) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        initialValues={this.getFormInitData()}
        validate={
          listingType === LISTING_TYPES.CLASS || listingType === LISTING_TYPES.EVENT
            ? this.classTypeBookingValidation
            : undefined
        }
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            listing,
            currentUser,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            priceAddons,
            discountPriceAddons,
            fetchCalendarEventsInProgress,
            calendarEvents,
            fetchCalendarEventsError,
          } = fieldRenderProps;

          const pricePerUnitMessage = <FormattedMessage id="EditListingPricingForm.pricePerUnit" />;

          const listingType = listing.attributes.publicData.listing_type;
          const timeSlotsOnSelectedDate = values.timeSlotsOnSelectedDate;
          const credits =
            currentUser &&
            currentUser.attributes &&
            currentUser.attributes.profile.privateData.sessions &&
            currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
              ? currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
              : 0;
          const hasCredits = credits && credits > 0;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const { invalidDates, selectedNumberOfHours } = this.state;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          const selectedPriceAddons =
            values &&
            values.additionalItems &&
            priceAddons.filter(p => values.additionalItems.find(i => i === p.label));

          const selectedDiscountPriceAddons =
            values &&
            values.discountAdditionalItems &&
            discountPriceAddons.filter(p =>
              values.discountAdditionalItems.find(i => i === p.label)
            );

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData = isProduct
            ? {
                unitType: unitType,
                quantity: 1,
                unitPrice,
              }
            : startDate && endDate
            ? {
                unitType:
                  values.price && values.price.amount > 0 ? config.bookingCustomAmount : unitType,
                unitPrice: values.price && values.price.amount > 0 ? values.price : unitPrice,
                startDate,
                endDate,
                // Calculate the quantity as hours between the booking start and booking end
                quantity: calculateQuantityFromHours(startDate, endDate),
                timeZone,
                priceAddons: selectedPriceAddons,
                discountPriceAddons: selectedDiscountPriceAddons,
                seats: Number(values.seats),
                period: Number(values.period),
              }
            : null;
          const bookingInfo = bookingData ? (
            <div className={css.priceBreakdownContainer}>
              {/* <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3> */}
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                listing={listing}
                currentUser={currentUser}
                isProduct={isProduct}
                listingType={listingType}
              />
            </div>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const { packages } = listing.attributes.publicData;
          const isPackagesTab = this.state.activeTab === 'packages';
          const defaultIndex = isPackagesTab ? 1 : 0;

          const defaultCredits = currentUser?.attributes?.profile?.protectedData?.DefaultCredits;
          const listingCurrency = listing?.attributes?.price.currency;
          const convertDefaultCredits = new Money(defaultCredits, listingCurrency);
          const price = listing.attributes.price;
          const { formattedPrice } =
            convertDefaultCredits.amount > 0 && priceData(convertDefaultCredits, intl);

          const isMembership =
            listing?.attributes?.publicData?.listing_type === LISTING_TYPES.MEMBERSHIP;
          const hasMembership =
            isMembership &&
            currentUser &&
            currentUser.attributes &&
            currentUser.attributes.profile.privateData.buyerSubscription &&
            currentUser.attributes.profile.privateData.buyerSubscription[listing.id.uuid];
          // const isProduct =
          //   listing?.attributes?.publicData?.listing_type === LISTING_TYPES.EDUCATION_COURSES;
          const areFieldsVisible = !isPackagesTab || (isPackagesTab && hasCredits);
          const periodValue =
            this.periodRef && this.periodRef.current ? this.periodRef.current.value : 0;
          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <>
                {!!areFieldsVisible && (
                  <div>
                    {!isProduct && monthlyTimeSlots && timeZone ? (
                      listingType === LISTING_TYPES.CLASS ||
                      listingType === LISTING_TYPES.COURSES ||
                      listingType === LISTING_TYPES.EVENT ? (
                        <ClassBookingFormFields
                          monthlyTimeSlots={monthlyTimeSlots}
                          timeZone={timeZone}
                          areFieldsVisible={areFieldsVisible}
                          isProduct={isProduct}
                          form={form}
                          listing={listing}
                          onManageDisableScrolling={onManageDisableScrolling}
                        />
                      ) : !isMembership || hasMembership || hasRequest ? (
                        listingType === LISTING_TYPES.PRODUCT ? (
                          Object.keys(monthlyTimeSlots)?.length &&
                          Object.values(monthlyTimeSlots)[0]?.timeSlots?.length && (
                            <FieldDateAndTimeInput
                              {...dateInputProps}
                              className={css.bookingDates}
                              listingId={listingId}
                              listingType={listingType}
                              bookingStartLabel={bookingStartLabel}
                              onFetchTimeSlots={onFetchTimeSlots}
                              monthlyTimeSlots={monthlyTimeSlots}
                              values={values}
                              intl={intl}
                              form={form}
                              pristine={pristine}
                              timeZone={timeZone}
                              credits={credits}
                              onInvalidDateTime={this.onInvalidDateTime}
                              isPackagesTab={isPackagesTab}
                              fetchCalendarEventsInProgress={fetchCalendarEventsInProgress}
                              calendarEvents={calendarEvents}
                              fetchCalendarEventsError={fetchCalendarEventsError}
                              bookingDataFacility={bookingDataFacility}
                              isTimeSlotesLoading={isTimeSlotesLoading}
                            />
                          )
                        ) : (
                          <FieldDateAndTimeInput
                            {...dateInputProps}
                            className={css.bookingDates}
                            listingId={listingId}
                            listingType={listingType}
                            bookingStartLabel={bookingStartLabel}
                            onFetchTimeSlots={onFetchTimeSlots}
                            monthlyTimeSlots={monthlyTimeSlots}
                            values={values}
                            intl={intl}
                            form={form}
                            pristine={pristine}
                            timeZone={timeZone}
                            credits={credits}
                            onInvalidDateTime={this.onInvalidDateTime}
                            isPackagesTab={isPackagesTab}
                            fetchCalendarEventsInProgress={fetchCalendarEventsInProgress}
                            calendarEvents={calendarEvents}
                            fetchCalendarEventsError={fetchCalendarEventsError}
                            bookingDataFacility={bookingDataFacility}
                            isTimeSlotesLoading={isTimeSlotesLoading}
                          />
                        )
                      ) : (
                        // )
                        // : isMembership && !hasMembership ? (
                        //   <div className={css.bookingHeading}>
                        //     <div className={css.desktopPriceContainer}>
                        //       <h2 className={css.desktopPriceValue}>
                        //         {formattedPrice}
                        //         <span className={css.desktopPerUnit}>
                        //           <FormattedMessage id={'BookingPanel.month'} />
                        //         </span>
                        //       </h2>
                        //     </div>
                        //     <div className={css.bookingHeadingContainer}>
                        //       <h2 className={css.bookingTitle}>{listing?.attributes?.title}</h2>
                        //       {/* {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null} */}
                        //     </div>
                        //   </div>
                        <></>
                      )
                    ) : null}

                    {isPackagesTab && hasCredits && (
                      <div className={css.periodSelect}>
                        <FieldSelectOutline
                          id="period"
                          initialValue={ComponentOverlap ? '6' : '1'}
                          name="period"
                          label={'Select period'}
                          onChange={this.onPeriodChange}
                          inputRef={this.periodRef}
                        >
                          <option value="1">1 week</option>
                          <option value="2">2 weeks</option>
                          <option value="3">3 weeks</option>
                          <option value="4">4 weeks</option>
                          <option value="6">6 weeks</option>
                          <option value="8">8 weeks</option>
                          <option value="10">10 weeks</option>
                        </FieldSelectOutline>
                      </div>
                    )}

                    {invalidDates ? (
                      <span className={css.invalidDates}>
                        <FormattedMessage
                          id="BookingTimeForm.invalidDates"
                          values={{ credits, selectedNumberOfHours, periodValue }}
                        />
                      </span>
                    ) : null}

                    {!isProduct &&
                      listingType !== LISTING_TYPES.FACILITY &&
                      listingType !== LISTING_TYPES.LISTING &&
                      !this.state.isCustomModalOpen &&
                      timeSlotsOnSelectedDate &&
                      timeSlotsOnSelectedDate[0] &&
                      defaultIndex === 0 && (
                        <CustomFieldTextInput
                          className={css.seatsField}
                          id={`seats`}
                          name={`seats`}
                          type="number"
                          label={intl.formatMessage({
                            id:
                              listingType === LISTING_TYPES.PRODUCT
                                ? 'BookingTimeForm.ProductLabel'
                                : 'BookingTimeForm.seatsLabel',
                          })}
                          initialValue={'1'}
                          min={1}
                          max={timeSlotsOnSelectedDate[0].attributes.seats}
                          validate={composeValidators(isSeatsValueValid, value =>
                            value <= timeSlotsOnSelectedDate[0].attributes.seats && value > 0
                              ? undefined
                              : `Must be more then 0 and less then ${timeSlotsOnSelectedDate[0].attributes.seats}`
                          )}
                        />
                      )}

                    {!isPackagesTab &&
                      priceAddons &&
                      !!priceAddons?.length &&
                      priceAddons.map((addon, index) => (
                        <div key={`addon${index}`} className={css.priceAddon}>
                          <FieldCheckbox
                            id={`${addon.label}`}
                            label={addon.label}
                            name={'additionalItems'}
                            value={addon.label}
                            className={css.addonLabel}
                          />
                          <span className={css.priceAddonAmount}>
                            {formatMoney(intl, addon.price)}
                          </span>
                        </div>
                      ))}

                    {!isPackagesTab &&
                      discountPriceAddons &&
                      !!discountPriceAddons?.length &&
                      discountPriceAddons.map((discountAddon, index) => (
                        <div key={`discountaddon${index}`} className={css.priceAddon}>
                          <FieldCheckbox
                            id={`${discountAddon.label}`}
                            label={discountAddon.label}
                            name={'discountAdditionalItems'}
                            value={discountAddon.label}
                            className={css.addonLabel}
                          />
                          <span className={css.priceAddonAmount}>{discountAddon.amount}%</span>
                        </div>
                      ))}
                  </div>
                )}
              </>

              <div className={submitButtonClasses}>
                {!isMembership && !isProduct && (
                  <div className={css.paymentMain}>
                    {!ComponentOverlap && (
                      <p className={css.paymmentWrapper}>
                        <input
                          type="radio"
                          id={ComponentOverlap ? 'test3' : 'test1'}
                          name="radio-group"
                          onClick={() => {
                            this.onSelect(0);
                          }}
                          defaultChecked={defaultIndex === 0 ? true : false}
                        />
                        <label htmlFor={ComponentOverlap ? 'test3' : 'test1'}>Direct Payment</label>
                      </p>
                    )}
                    <p>
                      <input
                        type="radio"
                        id={ComponentOverlap ? 'test4' : 'test2'}
                        name="radio-group"
                        onClick={() => {
                          this.onSelect(1);
                        }}
                        defaultChecked={defaultIndex === 1 ? true : false}
                      />
                      <label htmlFor={ComponentOverlap ? 'test4' : 'test2'}>Packages</label>
                    </p>
                  </div>
                )}
                {!isPackagesTab ? bookingInfo : null}
                {this.state.isCustomModalOpen && (
                  <FieldCurrencyInput
                    id="price"
                    name={'price'}
                    className={css.priceInput}
                    autoFocus
                    label={pricePerUnitMessage}
                    placeholder={'Choose your price…'}
                    currencyConfig={config.currencyConfig}
                    // validate={priceValidators}
                    onPriceChange={this.onChangePrice}
                  />
                )}

                {defaultIndex === 0 && (
                  <>
                    {isProduct ? (
                      <div className={css.submitButtonPanel}>
                        <PrimaryButton
                          type="submit"
                          onClick={() => {
                            form.change('withPayment', true);
                            form.change('withCredits', false);
                            form.change('productBooking', true);
                          }}
                        >
                          <FormattedMessage id="BookingTimeForm.continue" />
                        </PrimaryButton>
                        {hasRequest && (
                          <PrimaryButton
                            type="submit"
                            className={css.requestToBookWithCredits}
                            onClick={() => {
                              form.change('withPayment', false);
                              form.change('withCredits', true);
                              form.change('requestBooking', true);
                              form.change('productBooking', true);
                            }}
                            disabled={invalidDates}
                          >
                            <FormattedMessage
                              id={
                                hasRequestOnly
                                  ? 'BookingTimeForm.continue'
                                  : 'BookingTimeForm.requestBooking'
                              }
                            />
                          </PrimaryButton>
                        )}
                      </div>
                    ) : !isMembership ? (
                      <div className={css.submitButtonPanel}>
                        {!hasRequestOnly && (
                          <PrimaryButton
                            type="submit"
                            onClick={() => {
                              form.change('withPayment', true);
                              form.change('withCredits', false);
                            }}
                          >
                            <FormattedMessage id="BookingTimeForm.continue" />
                          </PrimaryButton>
                        )}

                        {listingType !== LISTING_TYPES.PRODUCT && !hasRequestOnly && (
                          <button
                            className={classNames(css.sendCustomAmount, {
                              [css.disabled]: !bookingData,
                            })}
                            onClick={e => {
                              e.preventDefault();
                              !!bookingData && this.setState({ isCustomModalOpen: true });
                            }}
                          >
                            <FormattedMessage id="BookingTimeForm.Send Custom Amount" />
                          </button>
                        )}

                        {hasRequest && (
                          <PrimaryButton
                            type="submit"
                            className={css.requestToBookWithCredits}
                            onClick={() => {
                              form.change('withPayment', false);
                              form.change('withCredits', true);
                              form.change('requestBooking', true);
                            }}
                            disabled={invalidDates}
                          >
                            <FormattedMessage
                              id={
                                hasRequestOnly
                                  ? 'BookingTimeForm.continue'
                                  : 'BookingTimeForm.requestBooking'
                              }
                            />
                          </PrimaryButton>
                        )}
                        {defaultCredits > 0 && convertDefaultCredits?.amount > price?.amount && (
                          <PrimaryButton
                            type="submit"
                            onClick={() => {
                              form.change('withDefaultCredits', true);
                              form.change('withPayment', false);
                              form.change('withCredits', true);
                            }}
                          >
                            Use {formattedPrice}
                          </PrimaryButton>
                        )}
                      </div>
                    ) : (
                      <div className={css.submitButtonPanel}>
                        {!!hasMembership && (
                          <>
                            <PrimaryButton
                              type="submit"
                              className={css.requestToBookWithCredits}
                              onClick={() => {
                                form.change('withPayment', false);
                                form.change('withCredits', true);
                                form.change('requestBooking', true);
                              }}
                              disabled={invalidDates}
                            >
                              <FormattedMessage id={'BookingTimeForm.continue'} />
                            </PrimaryButton>
                            {/* <PrimaryButton
                              className={css.requestToBookWithCredits}
                              onClick={() =>
                                this.props.onCancelSubscription &&
                                this.props.onCancelSubscription(hasMembership)
                              }
                              disabled={invalidDates}
                            >
                              <FormattedMessage id={'BookingTimeForm.cancelSubscription'} />
                            </PrimaryButton> */}
                          </>
                        )}
                        {!hasMembership && (
                          <>
                            <PrimaryButton
                              onClick={e => {
                                e.preventDefault();
                                this.props.addSubscription && this.props.addSubscription();
                              }}
                            >
                              <FormattedMessage id="BookingTimeForm.addSubscription" />
                            </PrimaryButton>
                            <PrimaryButton
                              type="submit"
                              className={css.requestToBookWithCredits}
                              onClick={() => {
                                form.change('withPayment', false);
                                form.change('withCredits', true);
                                form.change('requestBooking', true);
                              }}
                              disabled={invalidDates}
                            >
                              <FormattedMessage id={'BookingTimeForm.requestBooking'} />
                            </PrimaryButton>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}

                {defaultIndex === 1 && (
                  <div className={css.submitButtonPanel}>
                    {!ComponentOverlap && <SimplePackages listing={listing} intl={intl} />}
                    {hasCredits ? (
                      <div>
                        <PrimaryButton
                          type="submit"
                          className={css.requestToBookWithCredits}
                          onClick={() => {
                            form.change('withPayment', false);
                            form.change('withCredits', true);
                          }}
                          disabled={invalidDates}
                        >
                          {ComponentOverlap ? (
                            <FormattedMessage id="BookingTimeForm.bookYourRecurringTimes" />
                          ) : (
                            <FormattedMessage id="BookingTimeForm.requestToBookWithCredits" />
                          )}
                        </PrimaryButton>
                      </div>
                    ) : null}
                    {!isOwnListing && packages && packages?.length > 0 && showTopup ? (
                      <div className={css.buyPackage}>
                        <PrimaryButton
                          onClick={e => {
                            e.preventDefault();
                            handlePackagesModalOpen();
                          }}
                        >
                          {hasCredits ? (
                            <FormattedMessage id="BookingTimeForm.topup" />
                          ) : (
                            <FormattedMessage id="BookingTimeForm.proceed" />
                          )}
                        </PrimaryButton>
                      </div>
                    ) : null}

                    {listing.attributes.publicData.packages?.length === 0 && isPackagesTab && (
                      <p className={css.smallPrint}>
                        <FormattedMessage id="BookingTimeForm.noPackages" />
                      </p>
                    )}

                    <p className={css.smallPrint}>
                      <FormattedMessage
                        id={
                          isOwnListing
                            ? 'BookingTimeForm.ownListing'
                            : 'BookingTimeForm.youWontBeChargedInfo'
                        }
                      />
                    </p>
                  </div>
                )}
                {/* <Tabs
                  defaultIndex={defaultIndex}
                  onSelect={index => {
                    this.onSelect(index);
                  }}
                  selectedTabClassName={css.activeTab}
                >
                  <TabList className={css.tabs}>
                    <Tab className={css.inactiveTab}>Direct Payment</Tab>
                    <Tab className={css.inactiveTab}>Packages</Tab>
                  </TabList>
                  <TabPanel id="direct-payment">
                    <div>
                      <PrimaryButton
                        type="submit"
                        onClick={() => {
                          form.change('withPayment', true);
                          form.change('withCredits', false);
                        }}
                      >
                        <FormattedMessage id="BookingTimeForm.proceed" />
                      </PrimaryButton>
                    </div>
                  </TabPanel>

                  <TabPanel id="packages">

                  </TabPanel>
                </Tabs> */}
              </div>
              {/* {showContinueAndSkipButton === true && (
                <div className={css.continueAndSkipContainer}>
                  <PrimaryButton
                    type="submit"
                    onClick={() => {
                      form.change('withPayment', true);
                      form.change('withCredits', false);
                      form.change("payment_method", this.props.listing.attributes.publicData.payment_method || []);
                    }}
                  >
                    <FormattedMessage id="BookingTimeForm.continue" />
                  </PrimaryButton>
                  <SecondaryButton
                    type="button"
                    onClick={onSkip}
                  >
                    <FormattedMessage id="BookingTimeForm.skip" />
                  </SecondaryButton>
                </div>
              )} */}
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  priceAddons: PropTypes.array,
  discountPriceAddons: PropTypes.array,
  isProduct: bool,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,

  onManageDisableScrolling: func,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
