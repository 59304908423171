import pick from 'lodash/pick';
import config from '../../config';
import moment from 'moment';
import { types as sdkTypes } from '../../util/sdkLoader';
import { util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities, ensureCURLListings } from '../../util/data';
import { findNextBoundary, monthIdStringInTimeZone, nextMonthFn } from '../../util/dates';
import {
  COMPLETE_TRANSITIONS,
  filterTxByListing,
  TRANSITION_ENQUIRE,
} from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { denormalisedEntities, updatedEntities } from 'util/data';
import { initiatePrivileged, transactionLineItems } from 'util/api';
import axios from 'axios';
import qs from 'qs';
import { fetchCalendarEvents, addSubscription, deleteSubscription } from '../../util/api';

const { UUID } = sdkTypes;
// const flexIntegrationSdk = require('sharetribe-flex-integration-sdk');

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const ADD_MEMBERSHIP_REQUEST = 'app/ListingPage/ADD_MEMBERSHIP_REQUEST';
export const ADD_MEMBERSHIP_SUCCESS = 'app/ListingPage/ADD_MEMBERSHIP_SUCCESS';

export const DELETE_MEMBERSHIP_REQUEST = 'app/ListingPage/DELETE_MEMBERSHIP_REQUEST';
export const DELETE_MEMBERSHIP_SUCCESS = 'app/ListingPage/DELETE_MEMBERSHIP_SUCCESS';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const HITPAY_PROCESS = 'app/ListingPage/HITPAY_PROCESS';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SET_AUTHORS_LISTINGS_REFS = 'app/ListingPage/SET_AUTHORS_LISTINGS_REFS';
export const SET_AUTHORS_PRODUCT_LISTINGS_REFS =
  'app/ListingPage/SET_AUTHORS_PRODUCT_LISTINGS_REFS';

export const SET_AUTHORS_FACILITIES_REFS = 'app/ListingPage/SET_AUTHORS_FACILITIES_REFS';

export const FETCH_BOOKING_AMOUNT_REQUEST = 'app/ListingPage/FETCH_BOOKING_AMOUNT_REQUEST';
export const FETCH_BOOKING_AMOUNT_SUCCESS = 'app/ListingPage/FETCH_BOOKING_AMOUNT_SUCCESS';
export const FETCH_BOOKING_AMOUNT_ERROR = 'app/ListingPage/FETCH_BOOKING_AMOUNT_ERROR';

export const FETCH_AUTHOR_CLASSES_REQUEST = 'app/ListingPage/FETCH_AUTHOR_CLASSES_REQUEST';
export const FETCH_AUTHOR_CLASSES_SUCCESS = 'app/ListingPage/FETCH_AUTHOR_CLASSES_SUCCESS';
export const FETCH_AUTHOR_CLASSES_ERROR = 'app/ListingPage/FETCH_AUTHOR_CLASSES_ERROR';

export const FETCH_AUTHOR_FACILITY_REQUEST = 'app/ListingPage/FETCH_AUTHOR_FACILITY_REQUEST';
export const FETCH_AUTHOR_FACILITY_SUCCESS = 'app/ListingPage/FETCH_AUTHOR_FACILITY_SUCCESS';
export const FETCH_AUTHOR_FACILITY_ERROR = 'app/ListingPage/FETCH_AUTHOR_FACILITY_ERROR';

export const FACILITY_TIMESLOT_SUCCESS = 'app/ListingPage/FACILITY_TIMESLOT_SUCCESS';
export const BOOKED_TIMESLOT_SUCCESS = 'app/ListingPage/BOOKED_TIMESLOT_SUCCESS';
export const FACILITY_TIMESLOT_REQUEST = 'app/ListingPage/FACILITY_TIMESLOT_REQUEST';
export const REQUEST_TIMESLOTS = 'app/ListingPage/REQUEST_TIMESLOTS';

export const FETCH_AUTHOR_COMPANY_DETAILS_SUCCESS =
  'app/ListingPage/FETCH_AUTHOR_COMPANY_DETAILS_SUCCESS';

export const ADD_OPENED_LISTING = 'app/ListingPage/ADD_OPENED_LISTING';

export const FETCH_ALL_LISTING_REVIEWS = 'app/LandingPage/FETCH_ALL_LISTING_REVIEWS';

export const FETCH_CURRENT_LISTING_AUTHOR_REWIES =
  'app/LandingPage/FETCH_CURRENT_LISTING_AUTHOR_REWIES';

export const FETCH_ALL_CLASSES_REVIEWS = 'app/LandingPage/FETCH_ALL_CLASSES_REVIEWS';

export const ADD_BOOKING_DATA = 'app/LandingPage/ADD_BOOKING_DATA';

export const FETCH_CALENDAR_EVENTS_REQUEST = 'app/ListingPage/FETCH_CALENDAR_EVENTS_REQUEST';
export const FETCH_CALENDAR_EVENTS_SUCCESS = 'app/ListingPage/FETCH_CALENDAR_EVENTS_SUCCESS';
export const FETCH_CALENDAR_EVENTS_ERROR = 'app/ListingPage/FETCH_CALENDAR_EVENTS_ERROR';

export const ADD_CUSTOM_AMOUNT = 'app/ListingPage/ADD_CUSTOM_AMOUNT';
export const REMOVE_CUSTOM_AMOUNT = 'app/ListingPage/REMOVE_CUSTOM_AMOUNT';

// ================ Reducer ================ //

const initialState = {
  id: null,
  listingRef: [],
  showListingError: null,
  reviews: [],
  authorsListingsRefs: [],
  authorsListingsFacilityRefs: [],
  authorsCompanyListingDetails: {},
  authorsProductListingsRefs: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2019-12': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  amountOfBookings: 0,

  authorClassesIsLoading: false,
  authorClassesRefs: [],
  authorClassesError: null,
  authorFacilitiesRef: [],
  authorFacilitiesIsLoading: false,
  authorFacilitiesError: null,
  listingAllReviews: {},
  allClassListingReviews: {},
  fetchCalendarEventsInProgress: false,
  calendarEvents: [],
  fetchCalendarEventsError: null,
  currentListingAuthorReviews: {},
  customAmount: null,
  facilityTimeslots: [],
  bookedTimeslots: [],
  transactionHitpay: null,
  bookingData: {},
  isTimeSlotesLoading: false,
  addingMembership: false,
  membershipData: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case ADD_OPENED_LISTING:
      return { ...state, listingRef: [payload] };

    case HITPAY_PROCESS:
      return { ...state, transactionHitpay: payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case REQUEST_TIMESLOTS: {
      return { ...state, isTimeSlotesLoading: true };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots, isTimeSlotesLoading: false };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case SET_AUTHORS_LISTINGS_REFS:
      return { ...state, authorsListingsRefs: payload };

    case SET_AUTHORS_FACILITIES_REFS:
      return { ...state, authorsListingsFacilityRefs: payload };
    case SET_AUTHORS_PRODUCT_LISTINGS_REFS:
      return { ...state, authorsProductListingsRefs: payload };

    case FETCH_BOOKING_AMOUNT_REQUEST:
      return { ...state, amountOfBookings: 0 };
    case FETCH_BOOKING_AMOUNT_SUCCESS:
      return { ...state, amountOfBookings: payload };

    case FETCH_AUTHOR_CLASSES_REQUEST:
      return { ...state, authorClassesIsLoading: true };
    case FETCH_AUTHOR_CLASSES_SUCCESS:
      return { ...state, authorClassesIsLoading: false, authorClassesRefs: payload };
    case FETCH_AUTHOR_CLASSES_ERROR:
      return { ...state, authorClassesIsLoading: false, authorClassesError: payload };

    case FETCH_AUTHOR_FACILITY_REQUEST:
      return { ...state, authorFacilitiesIsLoading: true };
    case FETCH_AUTHOR_FACILITY_SUCCESS:
      return { ...state, authorFacilitiesIsLoading: false, authorFacilitiesRef: payload };
    case FETCH_AUTHOR_FACILITY_ERROR:
      return { ...state, authorFacilitiesIsLoading: false, authorFacilitiesError: payload };

    case FACILITY_TIMESLOT_REQUEST:
      return { ...state, facilityTimeslots: [] };

    case FACILITY_TIMESLOT_SUCCESS:
      return {
        ...state,
        facilityTimeslots: [
          ...state.facilityTimeslots,
          { id: payload.id, bookingSlotes: payload.data, timezone: payload.timezone },
        ],
      };

    case BOOKED_TIMESLOT_SUCCESS:
      return {
        ...state,
        bookedTimeslots: [
          ...state.bookedTimeslots,
          { id: payload.id, bookingSlotes: payload.data, timezone: payload.timezone },
        ],
      };

    // reviews
    case FETCH_ALL_LISTING_REVIEWS:
      return { ...state, listingAllReviews: payload };
    case FETCH_CURRENT_LISTING_AUTHOR_REWIES:
      return { ...state, currentListingAuthorReviews: payload };
    case FETCH_ALL_CLASSES_REVIEWS:
      return { ...state, allClassListingReviews: payload };

    case FETCH_AUTHOR_COMPANY_DETAILS_SUCCESS:
      return { ...state, authorsCompanyListingDetails: payload };

    // Fetch Gooogle calendar scheduled events
    case FETCH_CALENDAR_EVENTS_REQUEST:
      return { ...state, fetchCalendarEventsInProgress: true, fetchCalendarEventsError: null };
    case FETCH_CALENDAR_EVENTS_SUCCESS:
      return { ...state, fetchCalendarEventsInProgress: false, calendarEvents: payload };
    case FETCH_CALENDAR_EVENTS_ERROR:
      return { ...state, fetchCalendarEventsInProgress: false, fetchCalendarEventsError: payload };
    case ADD_CUSTOM_AMOUNT:
      return { ...state, customAmount: payload };
    case ADD_BOOKING_DATA:
      return { ...state, bookingData: payload };
    case REMOVE_CUSTOM_AMOUNT:
      return { ...state, customAmount: null };
    case ADD_MEMBERSHIP_REQUEST:
      return { ...state, addingMembership: true };
    case ADD_MEMBERSHIP_SUCCESS:
      return { ...state, addingMembership: false, membershipData: payload };
    case DELETE_MEMBERSHIP_REQUEST:
      return { ...state, deletingMembership: true };
    case DELETE_MEMBERSHIP_SUCCESS:
      return { ...state, deletingMembership: false };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const addOpenedListing = payload => ({
  type: ADD_OPENED_LISTING,
  payload,
});

export const addHitpay = payload => ({
  type: HITPAY_PROCESS,
  payload,
});

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const addBookingData = data => ({
  type: ADD_BOOKING_DATA,
  payload: data,
});

export const addMembershiprequest = () => ({ type: ADD_MEMBERSHIP_REQUEST });
export const addMembershipsuccess = data => ({ type: ADD_MEMBERSHIP_SUCCESS, payload: data });

export const deleteSubscriptionrequest = () => ({ type: DELETE_MEMBERSHIP_REQUEST });
export const deleteSubscriptionsuccess = data => ({
  type: DELETE_MEMBERSHIP_SUCCESS,
  payload: data,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const setAuthorsListingsRefs = payload => ({ type: SET_AUTHORS_LISTINGS_REFS, payload });

export const setAuthorFacilityRefs = payload => ({ type: SET_AUTHORS_FACILITIES_REFS, payload });

export const setAuthorsProductListingsRefs = payload => ({
  type: SET_AUTHORS_PRODUCT_LISTINGS_REFS,
  payload,
});

export const fetchBookingAmountRequest = () => ({ type: FETCH_BOOKING_AMOUNT_REQUEST });
export const fetchBookingAmountSuccess = payload => ({
  type: FETCH_BOOKING_AMOUNT_SUCCESS,
  payload,
});

export const fetchAuthorClassesRequest = () => ({ type: FETCH_AUTHOR_CLASSES_REQUEST });
export const fetchAuthorClassesSuccess = reviews => ({
  type: FETCH_AUTHOR_CLASSES_SUCCESS,
  payload: reviews,
});
export const fetchAuthorClassesError = error => ({
  type: FETCH_AUTHOR_CLASSES_ERROR,
  error: true,
  payload: error,
});

export const setisTimeSlotesLoading = () => ({ type: REQUEST_TIMESLOTS });

export const fetchAuthorFacilitiesRequest = () => ({ type: FETCH_AUTHOR_FACILITY_REQUEST });
export const fetchAuthorFacilitiesSuccess = reviews => ({
  type: FETCH_AUTHOR_FACILITY_SUCCESS,
  payload: reviews,
});
export const fetchAuthorFacilitiesError = error => ({
  type: FETCH_AUTHOR_FACILITY_ERROR,
  error: true,
  payload: error,
});

export const allListingReviews = reviewData => ({
  type: FETCH_ALL_LISTING_REVIEWS,
  payload: reviewData,
});

export const currentListingAutherReviews = reviewData => ({
  type: FETCH_CURRENT_LISTING_AUTHOR_REWIES,
  payload: reviewData,
});
export const actionAllClassListingReviews = reviewData => ({
  type: FETCH_ALL_CLASSES_REVIEWS,
  payload: reviewData,
});

export const fetchAuthorCompanyListingDetailsSuccess = data => ({
  type: FETCH_AUTHOR_COMPANY_DETAILS_SUCCESS,
  payload: data,
});

export const fetchCalendarEventsRequest = () => ({ type: FETCH_CALENDAR_EVENTS_REQUEST });
export const fetchCalendarEventsSuccess = payload => ({
  type: FETCH_CALENDAR_EVENTS_SUCCESS,
  payload: payload,
});
export const fetchCalendarEventsError = e => ({
  type: FETCH_CALENDAR_EVENTS_ERROR,
  error: true,
  payload: e,
});

export const addingCustomPrice = payload => ({
  type: ADD_CUSTOM_AMOUNT,
  payload: payload,
});

export const removingCustomPrice = () => ({
  type: REMOVE_CUSTOM_AMOUNT,
});

export const facilityTimeSlotesSuccess = payload => ({
  type: FACILITY_TIMESLOT_SUCCESS,
  payload: payload,
});

export const bookedTimeSlotesSuccess = payload => ({
  type: BOOKED_TIMESLOT_SUCCESS,
  payload: payload,
});

export const facilityTimeSlotesRequest = () => ({
  type: FACILITY_TIMESLOT_REQUEST,
});

// ================ Thunks ================ //

export const fetchAuthorClasses = (authorId, dates) => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchAuthorClassesRequest());
    const { start, end } = dates;
    const startParams = !!start ? { start: start } : {};
    const endParams = !!end ? { end: end } : {};

    const response = await axios.get(config.serverBaseUrl + 'listings', {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
      params: {
        authorId: authorId,
        pub_listing_type: 'class',
        availability: 'time-partial',
        ...startParams,
        ...endParams,
        include: 'author,author.profileImage,images',
        'fields.image':
          'variants.scaled-small,variants.square-small2x,variants.square-small,variants.square-small2x',
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });
    const ensureResponse = ensureCURLListings(response);

    dispatch(addMarketplaceEntities(ensureResponse));
    dispatch(
      fetchAuthorClassesSuccess(ensureResponse.data.data.map(({ id, type }) => ({ id, type })))
    );
    dispatch(
      fetchAllClassListingReviews(ensureResponse.data.data.map(({ id, type }) => ({ id, type })))
    );

    return ensureResponse;
  } catch (e) {
    dispatch(fetchAuthorClassesError(storableError(e)));
    throw e;
  }
};

export const fetchAuthorCourses = (authorId, dates) => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchAuthorClassesRequest());
    const { start, end } = dates;
    const startParams = !!start ? { start: start } : {};
    const endParams = !!end ? { end: end } : {};

    const response = await axios.get(config.serverBaseUrl + 'listings', {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
      params: {
        authorId: authorId,
        pub_listing_type: 'courses',
        availability: 'time-partial',
        ...startParams,
        ...endParams,
        include: 'author,author.profileImage,images',
        'fields.image':
          'variants.scaled-small,variants.square-small2x,variants.square-small,variants.square-small2x',
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });
    const ensureResponse = ensureCURLListings(response);

    dispatch(addMarketplaceEntities(ensureResponse));
    dispatch(
      fetchAuthorClassesSuccess(ensureResponse.data.data.map(({ id, type }) => ({ id, type })))
    );
    dispatch(
      fetchAllClassListingReviews(ensureResponse.data.data.map(({ id, type }) => ({ id, type })))
    );

    return ensureResponse;
  } catch (e) {
    dispatch(fetchAuthorClassesError(storableError(e)));
    throw e;
  }
};

export const fetchAuthorFacilities = (authorId, dates) => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchAuthorFacilitiesRequest());
    const { start, end } = dates;
    const startParams = !!start ? { start: start } : {};
    const endParams = !!end ? { end: end } : {};

    const response = await axios.get(config.serverBaseUrl + 'listings', {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
      params: {
        authorId: authorId,
        pub_listing_type: 'facility',
        availability: 'time-partial',
        ...startParams,
        ...endParams,
        include: 'author,author.profileImage,images',
        'fields.image':
          'variants.scaled-small,variants.square-small2x,variants.square-small,variants.square-small2x',
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });
    const ensureResponse = ensureCURLListings(response);

    dispatch(addMarketplaceEntities(ensureResponse));
    dispatch(
      fetchAuthorFacilitiesSuccess(ensureResponse.data.data.map(({ id, type }) => ({ id, type })))
    );
    // dispatch(
    //   fetchAllClassListingReviews(ensureResponse.data.data.map(({ id, type }) => ({ id, type })))
    // );

    return ensureResponse;
  } catch (e) {
    dispatch(fetchAuthorFacilitiesError(storableError(e)));
    throw e;
  }
};

export const fetchAllClassListingReviews = classListingRef => (dispatch, getState, sdk) => {
  let listingReview = {};
  classListingRef.forEach(async (ref, index) => {
    try {
      const resp = await sdk.reviews.query({
        listing_id: ref.id,
        state: 'public',
        include: ['author', 'author.profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      });
      if (resp.data.data.length !== 0) {
        const reviews = denormalisedResponseEntities(resp);
        listingReview[ref.id.uuid] = reviews;
      }
    } catch (error) {
      console.log(error);
    }
    if (index === classListingRef.length - 1) {
      dispatch(actionAllClassListingReviews(listingReview));
    }
  });
};

export const updateListingStatus = (listingId, authorId) => async (dispatch, getState, sdk) => {
  const state = getState();
  const currentUserId = state.user.currentUser ? state.user.currentUser.id.uuid : '';
  if (currentUserId !== authorId) {
    return;
  }

  try {
    const params = {
      id: listingId,
      publicData: {
        last_active: moment().valueOf(),
      },
    };
    await sdk.ownListings.update(params);
  } catch (e) {
    console.log(e);
  }
};

export const showListing = (listingId, isOwn = false) => async (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',

      //Custom
      'variants.company-background-crop',
    ],
    'imageVariant.company-background-crop': sdkUtil.objectQueryString({
      w: 1920,
      h: 473,
      fit: 'crop',
    }),
  };

  try {
    const listing = isOwn ? await sdk.ownListings.show(params) : await sdk.listings.show(params);
    const authorId = listing.data.data.relationships.author.data.id.uuid;
    const listingType = listing.data.data.attributes.publicData.listing_type || '';
    if (listingType !== 'enquiry') dispatch(updateListingStatus(listingId, authorId));

    const authorCompanyListings = await sdk.listings.query({
      authorId: authorId,
      pub_listing_type: 'company',
      include: ['author'],
    });
    const authorCompanyListing =
      authorCompanyListings.data.data.length > 0 ? authorCompanyListings.data.data[0] : {};
    const authorCompanyDetails = authorCompanyListing.id
      ? { title: authorCompanyListing.attributes.title, id: authorCompanyListing.id.uuid }
      : {};
    dispatch(fetchAuthorCompanyListingDetailsSuccess(authorCompanyDetails));

    dispatch(addMarketplaceEntities(listing));
    // dispatch(addMarketplaceEntities(authorListings));
    dispatch(addOpenedListing({ id: listing.data.data.id.uuid, type: 'listing' }));
    // dispatch(setAuthorsListingsRefs(authorListingsRefs));
    return listing;
  } catch (e) {
    dispatch(showListingError(storableError(e)));
  }
};

export const showEducationListing = (authorId, params) => async (dispatch, getState, sdk) => {
  try {
    const authorListings = await sdk.listings.query({
      authorId: authorId.uuid,
      ...params,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    });

    const authorListingsRefs = authorListings.data.data.map(({ id, type }) => ({ id, type }));
    dispatch(addMarketplaceEntities(authorListings));
    if (params.pub_listing_type === 'facility') {
      dispatch(setAuthorFacilityRefs(authorListingsRefs));
    } else {
      dispatch(setAuthorsListingsRefs(authorListingsRefs));
    }

    return;
  } catch (e) {
    console.log('---Could not load listing. Please try again.', e);
  }
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
      return reviews;
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const timeSlotsRequestFacility = params => async (dispatch, getState, sdk) => {
  dispatch(facilityTimeSlotesRequest());
  try {
    sdk.timeslots.query(params).then(response => {
      const data = denormalisedResponseEntities(response);
      if (data && !!data.length) {
        dispatch(
          facilityTimeSlotesSuccess({ id: params.listingId, data: data, timezone: params.timezone })
        );
      }
    });
    sdk.bookings.query(params).then(res => {
      const data = res.data.data;
      const filterData =
        data.length &&
        data.map(val => ({
          start: val.attributes.start,
          end: val.attributes.end,
          state: val.attributes.state,
        }));
      if (filterData && !!filterData.length) {
        dispatch(
          bookedTimeSlotesSuccess({
            id: params.listingId,
            data: filterData,
            timezone: params.timezone,
          })
        );
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const fetchBookingsNumber = listingId => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchBookingAmountRequest());
    const response = await sdk.transactions.query({
      only: 'sale',
      lastTransitions: COMPLETE_TRANSITIONS,
      include: ['listing'],
    });

    const refs = response.data.data.map(({ id, type }) => ({ type, id }));
    const stateEntities = updatedEntities({}, response.data);
    const txs = denormalisedEntities(stateEntities, refs);
    const filteredTxs = filterTxByListing(listingId, txs);

    dispatch(fetchBookingAmountSuccess(filteredTxs.length));
  } catch (e) {
    console.error(e);
  }
};

export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      // log.error(e, 'fetching-line-items-failed', {
      //   listingId: listingId.uuid,
      //   bookingData: bookingData,
      // });
    });
};

export const fetchGoogleCalendarEvents = params => (dispatch, getState, sdk) => {
  dispatch(fetchCalendarEventsRequest());
  const handleSuccess = response => {
    dispatch(fetchCalendarEventsSuccess(response.data.data.items));
    return response;
  };
  const handleError = e => {
    dispatch(fetchCalendarEventsError(storableError(e)));
  };
  return fetchCalendarEvents(params)
    .then(handleSuccess)
    .catch(handleError);
};

export const addMembershipSubscription = params => async (dispatch, getState, sdk) => {
  dispatch(addMembershiprequest());
  try {
    const response = await addSubscription(params);
    dispatch(addMembershipsuccess(response.id));
  } catch (e) {
    console.log(e);
  }
};

export const cancelSubscription = data => async (dispatch, getState, sdk) => {
  dispatch(deleteSubscriptionrequest());
  try {
    const response = await deleteSubscription(data);
    dispatch(deleteSubscriptionsuccess(response.id));
  } catch (e) {
    console.log(e);
  }
};
export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdStringInTimeZone(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    per_page: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
      // dispatch(fetchGoogleCalendarEvents({ listingId, start, end }));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const fetchAllListingReviews = listingRef => (dispatch, getState, sdk) => {
  let listingReview = {};
  listingRef.forEach(async (ref, index) => {
    try {
      const resp = await sdk.reviews.query({
        listing_id: ref.id,
        type: 'ofProvider',
        state: 'public',
        include: ['author', 'author.profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      });

      if (resp.data.data.length !== 0) {
        const reviews = denormalisedResponseEntities(resp);
        listingReview[ref.id.uuid] = reviews;
      }
    } catch (error) {
      console.log(error);
    }
    if (index === listingRef.length - 1) {
      dispatch(allListingReviews(listingReview));
    }
  });
};

export const fetchCurrentListingUserReviews = listingId => (dispatch, getState, sdk) => {
  let listingReview = {};
  listingId.forEach(async (ref, index) => {
    try {
      const resp = await sdk.reviews.query({
        subject_id: ref,
        // type: 'ofProvider',
        state: 'public',
        include: ['author', 'author.profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      });

      if (resp.data.data.length !== 0) {
        const reviews = denormalisedResponseEntities(resp);
        listingReview[ref?.uuid] = reviews;
      }
    } catch (error) {
      console.log(error);
    }
    if (index === listingId.length - 1) {
      dispatch(currentListingAutherReviews(listingReview));
    }
  });
};
export const createTranstion = param => async (dispatch, getState, sdk) => {
  console.log(param);
  return await initiatePrivileged(param)
    .then(res => {
      dispatch(addHitpay(res?.data?.data?.id?.uuid));
      return res?.data?.data?.id?.uuid;
    })
    .catch(e => console.log(e));
};
// Helper function for loadData call.
export const fetchMonthlyTimeSlots = listing => (dispatch, getState, sdk) => {
  dispatch(setisTimeSlotesLoading());
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    
    const nextBoundary = findNextBoundary(tz, new Date());
    const nextMonth = nextMonthFn(nextBoundary, tz, 1);
    const nextAfterNextMonth = nextMonthFn(nextMonth, tz, 1);
    const thirdMonth = nextMonthFn(nextAfterNextMonth, tz, 1)
    const fourMonth = nextMonthFn(thirdMonth, tz, 1)
    const fiveMonth = nextMonthFn(fourMonth, tz, 1)
    const sixMonth = nextMonthFn(fiveMonth, tz, 1)
    // const nextAfterNextMonth_2 = nextMonthFn(nextAfterNextMonth, tz);

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextAfterNextMonth, thirdMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, thirdMonth, fourMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, fourMonth, fiveMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, fiveMonth, sixMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return Promise.all([
    dispatch(showListing(listingId)),
    dispatch(fetchBookingsNumber(listingId)),
    dispatch(fetchReviews(listingId)),
  ]).then(responses => {
    if (responses[0] && responses[0].data && responses[0].data.data) {
      const listing = responses[0].data.data;

      // Fetch timeSlots.
      // This can happen parallel to loadData.
      // We are not interested to return them from loadData call.
      dispatch(fetchMonthlyTimeSlots(listing));
    }
    return responses;
  });
};
